export enum MotivationType {
  EscapismExcitementAndThrill = 'excitement and thrill',
  EscapismThinkingAndSolving = 'thinking and solving',
  ExplorationCollectingTreasure = 'collecting treasure',
  ExplorationDiscoveringNewWorlds = 'discovering new worlds',
  ExpressionCustomizationAndDecoration = 'customization and decoration',
  ExpressionRolePlayingAndEmotions = 'role playing and emotions',
  ManagementResourceOptimization = 'resource optimization',
  ManagementStrategicPlanning = 'strategic planning',
  MasteryCompletingMilestones = 'completing milestones',
  MasteryImprovingSkills = 'improving skills',
  SocialCompetingAgainstOthers = 'compete with others',
  SocialWorkingWithOthers = 'working with others',
}

export enum MotivationKey {
  Expressionist = 'Expressionist',
  KingOfTheHill = 'King of the hill',
  Networker = 'Networker',
  SkillMaster = 'Skll master',
  Strategist = 'Strategist',
  Thinker = 'Thinker',
  ThrillSeeker = 'Thrill seeker',
  TreasureHunter = 'Treasure Hunter',
}

type MotivationDescription = {
  description?: string
}

export type MotivationTypeWithDescription = MotivationDescription & { key: MotivationType }
export type MotivationKeyWithDescription = MotivationDescription & { key: MotivationKey }

export const allMotivationTypes: MotivationType[] = Object.values(MotivationType)
export const allMotivationKeys: MotivationKey[] = Object.values(MotivationKey)
