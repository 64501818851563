import { FC, ReactNode, useState } from 'react'

import { Tune } from '@mui/icons-material'
import { Grid, Dialog } from '@mui/material'

import { CounterButton } from '../../../../components/CounterButton/CounterButton'
import { AppType } from '../../../game/types/Game'
import { useLiveEventCommonFilterCounts } from '../../hooks/useLiveEventCommonFilterCounts'
import { LiveEventTrackerSearchMethod } from '../../types/LiveEventTrackerSearchMethod'
import { TLiveEventsCommonFilters, LiveEventsCommonFiltersData } from '../../types/LiveEventsCommonFilters'
import { TrackedGame } from '../../types/TrackedGame'
import { LiveEventsCommonFiltersDialog } from '../LiveEventsCommonFiltersDialog/LiveEventsCommonFiltersDialog'
import LiveEventsEventSearchAutocomplete from '../LiveEventsEventSearchAutocomplete/LiveEventsEventSearchAutocomplete'
import { ActiveCommonFilters } from './ActiveCommonFilters/ActiveCommonFilters'

/**
 * Component for Live Events Tracker view level filters, providing common filtering options for the entire view.
 *
 * @component
 * @param {TrackedGame[]} selectedGames - Array of selected games.
 * @param {LiveEventTrackerSearchMethod} searchMethod - Method used for searching live events.
 * @param {TLiveEventsCommonFilters} commonFilters - Common filters applied to live events.
 * @param {LiveEventsCommonFiltersData} commonFiltersData - Data related to common filters.
 * @param {string} eventNameSearchValue - Current value of the event name search input.
 * @param {string[]} liveEventNames - List of live event names for the autocomplete.
 * @param {(filters: TLiveEventsCommonFilters) => void} onFiltersChange - Callback function to handle changes in filters.
 * @param {(value: string) => void} onEventNameSearchValueChange - Callback function to handle changes in event name search value.
 * @param {ReactNode} [children] - Optional children elements to be rendered within the component.
 * @returns {JSX.Element} The rendered component.
 */
type LiveEventsViewFiltersProps = {
  selectedGames: TrackedGame[]
  searchMethod: LiveEventTrackerSearchMethod
  commonFilters: TLiveEventsCommonFilters
  commonFiltersData: LiveEventsCommonFiltersData
  eventNameSearchValue: string
  liveEventNames: string[]
  onFiltersChange: (filters: TLiveEventsCommonFilters) => void
  onEventNameSearchValueChange: (value: string) => void
  children?: ReactNode
}

export const LiveEventsViewFilters: FC<LiveEventsViewFiltersProps> = ({
  selectedGames,
  searchMethod,
  commonFilters,
  commonFiltersData,
  eventNameSearchValue,
  onFiltersChange,
  onEventNameSearchValueChange,
  liveEventNames,
}) => {
  const hdGameSelected = selectedGames.some((game) => game.game.appType === AppType.PC_CONSOLE)
  const [commonFiltersDialogOpen, setCommonFiltersDialogOpen] = useState<boolean>(false)
  const { activeCount } = useLiveEventCommonFilterCounts({ filters: commonFilters, data: commonFiltersData, hdGameSelected })

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <CounterButton count={activeCount} variant="contained" startIcon={<Tune />} onClick={() => setCommonFiltersDialogOpen(true)}></CounterButton>
        </Grid>
        <Grid item flexGrow={1}>
          <LiveEventsEventSearchAutocomplete
            value={eventNameSearchValue}
            onChange={onEventNameSearchValueChange}
            liveEventNames={liveEventNames}
          ></LiveEventsEventSearchAutocomplete>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <ActiveCommonFilters filters={commonFilters} filtersData={commonFiltersData} />
        </Grid>
      </Grid>

      <Dialog open={commonFiltersDialogOpen} onClose={() => setCommonFiltersDialogOpen(!commonFiltersDialogOpen)} maxWidth="lg" fullWidth>
        <LiveEventsCommonFiltersDialog
          hdGameSelected={hdGameSelected}
          searchMethod={searchMethod}
          onClose={() => setCommonFiltersDialogOpen(!commonFiltersDialogOpen)}
          onConfirm={onFiltersChange}
          filters={commonFilters}
          data={commonFiltersData}
        />
      </Dialog>
    </>
  )
}
